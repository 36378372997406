export const VERSION_TEST = '0.3.0';
export const VERSION_DEV = '0.6.0';
export const VERSION_PROD = '2.0.1';
export const OTHER_RELATIONSHIP_ID = '7';

export const API_URL = {

  //BASE_URL_LOCAL: 'http://192.168.0.52:3000',
  BASE_URL_LOCAL: 'http://localhost:3000',
  BASE_URL_PRUEBAS: 'http://pruebas-cedapi.vectorialgroup.com',
  BASE_URL_DEV: 'http://desarrollo-cedapi.vectorialgroup.com',
  BASE_URL_PROD: 'https://cedapi.ikanosmart.com',

  
  PATH: 'api/v1/',
  GET_INFORMATION_Q7: 'informationemployee',
  METHOD: {
    SESSION: 'session',
    REGISTRATION: 'registration',
    MISC: 'misc',
    AUTHORIZATION: 'authorization',
    INFORMATION: 'information',
    SPECIALIST: 'specialist',
    INTERVIEW: 'interview',
    PERIOD: 'period',
    CHILDREN: 'children',
    TEACHER: 'teacher',
    ASSISTANCE: 'assistance',
    MEDICINE: 'medicine',
    RESTRICTION: 'restriction',
    DIARY: 'note',
    CLASSROOM: 'classroom',
    DASHBOARD: 'dashboard',
    DIALY_REPORT: 'daily_report',
    MEASURES: 'measures',
    VACCINATIONS: 'vaccinations',
    WEEKLY_REPORT: 'weekly_report',
    PEDAGOLICAL_REPORT: 'pedagogical_report',
    SALARYRANGE: 'salaryrange',
    FOOD_MENU: 'food_menu',
    REPORT: 'report',
    TECHNICIANS: 'technician',
    Q7: 'q7'
  },
  session: {
    LOGIN: 'login',
    FORGOT: 'forgot_password',
    RESET: 'reset_password',
  },
  REGISTRATION: {
    CREATE_PREREGISTRATION: 'create_preregistration',
    GET_PREREGISTRATIONS: 'get_preregistrations',
    GET_REGISTRATIONS_COLABORATOR: 'get_registrations_colaborator',
    EVALUATE_PREREGISTRATION: 'evaluate_preregistration',
    REACTIVATE_REGISTRATION: 'reactivate_registration',
    SAVE_CHILDREN_INFORMATION: 'save_children_information',
    GET_REGISTRATIONS: 'get_registrations',
    GET_REGISTRATION_DETAIL: 'get_registration_detail',
    EVALUATE_REGISTRATION: 'evaluate_registration',
    GET_REGISTRATIONS_ADMIN: 'get_registrations_admin',
    EDIT_PREINSCRIPTION_ADMIN: 'edit_preinscription_admin',
    SEND_TO_APPROVAL: 'send_to_approval',
    CANCEL_REGISTRATION: 'cancel_registration',
    GET_LIST_REGISTRATIONS_PERIOD: 'get_list_registrations_period',
    GET_REGISTRATION_INFO: 'get_registration_info',
    PARENT_CONFIRM: 'parent_confirm',
    REGISTRATION_MASS: 'registration_mass',
    CONFIRM: 'confirm',
    CANCEL: 'cancel',
    RE_ENTRY: "re_entry"
  },
  CHILDREN: {
    GET_ACTIVES_PERIOD_GROUPED: 'get_actives_period_grouped',
    GET_ACTIVES_PERIOD: 'get_actives_period',
    PROMOTION_CHILDREN: 'promotion_children',
    GET_CHILDRENS_BY_STATUS: 'get_childrens_by_status',
    GET_RETIRED_CHILDRENS: 'get_retired_childrens',
    GET_DETAIL: 'get_children_detail',
    CURRENT_TOLERANCES: 'current_tolerances_children',
    SAVE_TOLERANCE: 'save_tolerance',
    UPDATE_TOLERANCE: 'update_tolerance',
    CHANGE_STATUS_TOLERANCE: 'update_status_tolerance',
    SAVE_TRACKING: 'save_tracking',
    GET_HOME: 'get_home',
    FINISH_PEDAGOLICAL: 'finish_pedagolical_report',
    GET_PARENT_HOME: 'get_parent_home',
    UPDATE_PHOTO: 'update_photo',
    GET_DIARY: 'get_diary',
    UPDATE_PARENT_PROM: 'update_parent_prom'
  },
  INFORMATION: {
    SAVE_REGISTRATION: 'save_registration',
    GET_REGISTRATION: 'get_registration',
    SAVE_AUTHORIZED_CONTACT: 'save_authorized_contact',
    DELETE_AUHOTIZED_CONTACT: 'delete_authorized_contact',
    GET_INFO_EMPLOYEE_TQ: 'get_info_employee_tq',
    UPDATE_INFORMATION: 'update_children_information',
    CHECK_PARENT: 'check_parent'
  },
  INTERVIEW: {
    SCHEDULE: 'schedule',
    UPDATE_SCHEDULE: 'update_schedule'
  },
  SPECIALIST: {
    GET_FORM: 'get_form',
    SAVE_FORM: 'save_form',
    GET_REGISTRATIONS: 'get_registrations'
  },
  PERIOD: {
    GET_ALL: 'get_all',
    CREATE: 'create',
    UPDATE: 'update',
    UPDATE_CURRENT: 'update_current',
    CREATE_DAY_NOT_WORKED: 'create_day_not_worked',
    CURRENT_PERIOD: 'get_current_period',
    ENABLE_PEDALOGICAL: 'enable_pedalogical_report',
    DISABLE_PEDALOGICAL: 'disable_pedalogical_report',
    LIST_NOT_WORKED_DAYS: 'list_not_worked_days',
    REMOVE_NOT_WORKED_DAY: 'remove_not_worked_day'
  },
  MISC: {
    SEARCHCOLABORATOR: 'search_colaborator',
    GETLISTS: 'get_lists',
    GETCITYBYSTATE: 'get_cities_by_state',
    GET_POLITICAL_ROUTES: 'get_political_routes',
    GETLOGSSOAP: 'get_logs_soap'
  },
  AUTHORIZATION: {
    CREATE: 'create',
    GETALL: 'get_all',
    UPDATE: 'update',
    DELETE: 'delete',
  },
  TEACHER: {
    GET_ALL: 'get_all',
    CREATE: 'create',
    UPDATE: 'update',
    ASSING_CLASS: 'assign_to_class_room',
    REMOVE_CLASS: 'revoke_class_room_teacher',
    CHANGE_STATUS: 'change_status',
    GROUPS_WITHOUT_TEACH: 'groups_without_teacher'
  },
  TECHNICIANS: {
    GET_ALL: 'get_all',
    CREATE: 'create',
    UPDATE: 'update',
    CHANGE_STATUS: 'change_status',
  },
  ASSISTANCE: {
    GET_CHILDRENS_CLASS_ROOM: 'get_childrens_class_room',
    SAVE_ASSISTANCE: 'save_assistance',
    PROLONGED_ABSENCE: 'prolonged_absence',
    INFORMATION_ASSISTANCE: 'information_assistance',
    INFORMATION_BY_GROUP: 'information_by_group',
    DASHBOARD_COOR: 'dashboard_coordinator',
    INFORMATION_CLASS_ROOM: 'information_class_room',
    SAVE_TRACKING: 'save_tracking'
  },
  MEDICINE: {
    CURRENT_MEDICINES_CHILDREN: 'current_medicines_children',
    SAVE: 'save_medicine',
    FINISH: 'finish',
    HISTORY: 'history_medicines_children',
    SAVE_DOSAGE: 'save_dosage',
    CURRENT_MEDICINE: 'current_medicine'
  },
  RESTRICTION: {
    SAVE_MEDICAL: 'save_medical',
    UPDATE_MEDICAL: 'update_medical_restriction',
    CURRENT_MEDICAL_CHILDREN: 'current_medical_children',
    SAVE_FOOD: 'save_food',
    UPDATE_FOOD: 'update_food_restriction',
    CURRENT_FOOD_CHILDREN: 'current_food_children',
    CHANGE_STATUS_FOOD: 'change_status_restriction_food',
    CHANGE_STATUS_MEDICAL: 'change_status_restriction_medical'
  },
  DIARY: {
    SAVE: 'save_note',
    GET_ALL: 'get_diary',
    ADD_COMMENT: 'add_comment',
    READ_NOTE: 'read_note',
    DELETE_NOTE: 'delete_note',
    SAVE_CLASS_ROOM: 'save_class_room_note',
    SEND_ALL_CHILDREN: 'send_note_all_childrens'
  },
  CLASSROOM: {
    GET_HOME: 'get_class_room_home',
    GET_PEDAGOLICAL_PROJECT: 'get_pedagolical_project',
    SAVE_PEDAGOLICAL_PROJECT: 'save_pedagogical_project',
    ADD_EXPERIENCE: 'add_classroom_experience',
    UPDATE_EXPERIENCE: 'update_classroom_experience',
    DELETE_EXPERIENCE: 'delete_classroom_experience',
  },
  DASHBOARD: {
    GET_DASHBOARD: 'get_dashboard'
  },
  DIALY_REPORT: {
    ADD: 'add'
  },
  MEASURES: {
    ADD: 'add_measure',
    UPDATE: 'update_measure',
    DELETE: 'delete_measure'
  },
  VACCINATIONS: {
    UPDATE_CHILDREN: 'update_children_vaccination'
  },
  WEEKLY_REPORT: {
    ADD: 'add'
  },
  PEDAGOLICAL_REPORT: {
    SAVE: 'save_information',
    FINISH_REPORT: 'finish_report',
    FINISH_PEDAGOLICAL_REPORT: 'finish_pedagolical_report',
    FINISH_PEDAGOLICAL_REPORT_COORDINADOR: 'finish_pedagolical_report_coordinator'

  },
  SALARYRANGE: {
    CREATE: 'create',
    INDEX: 'index',
    UPDATE: 'update'
  },
  FOOD_MENU: {
    SAVE: 'add_menu',
    GET_ALL: 'class_room_menus'
  },
  REPORT: {
    GETREPORT: 'getReport',
    GETREPORTSLISTS: 'getReportsList'
  },
  Q7: {
    SYNC_PARENT : 'sync_parents',
    SYNC_PARENT_STATUS: 'parents_sync_status'
  }
};

export const PATH_FORM = {
  CHILDREN: 'children',
  FAMILY: 'family',
  DOCUMENTATION: 'documentation',
  POLICY: 'policy',
  MEDICINAL: 'medical',
  SECURITY: 'security'
};

export const TOKEN_NAME = 'token';

export const USER_DATA = 'user_data';

export const URL_MANUALS = "http://usermanual.ikanosmart.com/login/";

export const MENU = 'user_menu';

export const LAYOUT = 'layout';

export const KEYAUTHHEADER = 'X-Auth-Token';

export const LISTS_KEY = 'lists';

export const CHILDREN = 'children';

export const STAGES = {
  MATRICULA: 'Matrícula',
  INSCRIPCION: 'Inscripción',
  PREINSCRIPCION: 'Preinscripción',
};

export const IDSTAGES = {
  PI_PENDING: 1,
  PI_APPROVED: 2,
  PI_CANCEL: 3,
  PI_REJECT: 4,
  I_PENDING: 5,
  I_FININSH: 6,
  REGISTERED: 7,
  I_CANCEL: 8,
  I_REJECT: 9,
  M_PENDING: 10,
  M_FINISH_PARENT: 11,
  M_CANCEL: 12,
  M_FINISH: 13,
  I_REENTRY: 14,
  I_REENTRY_FINISH: 15
};

export const ROLES = {
  1: 'admin',
  2: 'admin',
  3: 'enf',
  4: 'spec',
  5: 'doc',
  6: '', // COLABORADORES,
  7: 'tec',
  COORDINADOR: 2,
  COLABORATOR: 6
};

export const GROUPS = {
  1: 'mapaches',
  2: 'bezudos',
  3: 'malayos',
  4: 'andinos',
  5: 'pandas',
  6: 'kodiaks',
  7: 'grizzlys',
  8: 'polares'
};

export const VALUES = {
  SI: 1,
  NO: 0,
  servicios_medicos_domiciliarios: {
    OTRO: 4
  },
  nivel_cursos: {
    OTRO: 4
  },
  MASCULINO: 1,
  FEMENINO: 2,
};


export const LISTS = [
  'stage_name_status',
  'rangos_salarial',
  'periodos_academicos_actuales',
  'si_no',
  'parentescos',
  'tipos_vehiculo',
  'marcas_vehiculo',
  'cantidad',
  'salas_entrevista',
  'suenio',
  'cantidad_ninguno',
  'generos',
  'tipos_contrato',
  'motivos_retiro',
  'motivos_no_asistencia',
  'estados_pre_registro',
  'estados_registro',
  'tipos_tramite',
  'tipos_autorizacion',
  'tipos_sangre',
  'tipos_rh',
  'pos_adicionales',
  'servicios_medicos_domiciliarios',
  'tipos_espcialista',
  'departamentos_colombia',
  'estados_civiles',
  'niveles_academicos',
  'tipos_archivos_documentacion',
  'politicas',
  'tipos_embarazo',
  'duraciones_embarazo',
  'tipos_parto',
  'tipos_dificultad',
  'tiempo_horas',
  'calificaciones',
  'tipos_dieta',
  'tiempo_meses_medico',
  'edades',
  'eps',
  'edad_camina',
  'edad_aprendizaje',
  'tiempo_meses_medico_sin_na',
  'habitos_orales',
  'movimientos_orofaciales',
  'pruebas_auditivas',
  'liquidos',
  'instrumetos_para_comer',
  'tiempo_meses',
  'nivel_cursos',
  'valores',
  'si_no_a_veces',
  'antecedentes_familiares',
  'antecedentes_terapeuticos',
  'antecedentes_neurologicos',
  'instrumentos_parto',
  'posiciones_infante',
  'requerimientos_nacer',
  'tipos_convulsiones',
  'niveles_energia',
  'tiempo_dormir',
  'meses_especialista',
  'valores_sin_na',
  'causas_inasistencia',
  'perfil',
  'daily_report_food',
  'daily_report_sleep',
  'si_no_sin_na',
  'tipo_medicamento',
  'estado_medicamento',
  'gruposos',
  'alert_measure'
];

export const HABEAS_DATA =
  '<p>Aviso de privacidad<p>' +
  '<p class="text-justify">Tecnoquímicas S.A. (en adelante "Tecnoquímicas"), en su calidad de responsable de la administración de las bases de datos de sus empleados y las de sus sociedades filiales y subordinadas: Adhinter S.A.S., Arpack S.A.S., Coldesivos S.A.S., Constructeq S.A.S., Indugráficas S.A.S., Tecnofar TQ S.A.S., Tecnosur S.A., Laboratorios Teramed S.A. de C.V., Tecnoquímicas de Ecuador S.A., Tecnoquímicas S.A. Guatemala, TQ International Corp., Corporación BONIMA S.A. de C.V., Laboratorios Tecnoquímicas S.A. Panamá, Laboratorios Tecnoquímicas de Costa Rica S.A., Tecnoquímicas de Honduras S.L, Tecnoquímicas de Nicaragua S.A. y Tecnoquímicas de República Dominicana S.A.S., informa: Que sus datos personales están incluidos en nuestras bases de datos y en el marco de la ley y de la relación laboral que sostienen con las sociedades referidas podrán ser usados para alguno o algunos de estos fines necesarios:' +
  '</p>' +
  '<ul>' +
  '<li>Proceder a la gestión adecuada de la relación laboral</li>' +
  '<li>Cumplir con las obligaciones legales</li>' +
  '<li>Realizar los procesos necesarios para el pago de la nómina</li>' +
  '<li>Realizar actividades de prevención de riesgos laborales</li>' +
  '<li>Mantenerlo al tanto sobre actividades de bienestar o educación</li>' +
  '<li>Realizar tratamiento estadístico de los datos de nuestros empleados</li>' +
  '<li>Atender adecuadamente sus peticiones y/o solicitudes</li>' +
  '</ul>' +
  '<p class="text-justify">' +
  'Estos datos bajo su consentimiento podrán ser cedidos a otras entidades con las que Tecnoquímicas concierte directa o indirectamente la prestación de un servicio relativo a las funciones legítimas de la empresa y en el marco de la relación laboral, tales como bancos o aseguradoras.' +
  '</p>' +
  '<p class="text-justify">Usted, como titular de sus datos, puede consultar la Política de Privacidad y Tratamiento de Datos Personales de Tecnoquímicas S.A. mediante el acceso a nuestra página web www. tqconfiable.com, en donde se detalla el tratamiento, recolección, almacenamiento, uso y procesamiento de la información recogida, así como los procedimientos de consulta y reclamación que le permitirán hacer efectivos sus derechos al acceso, consulta, rectificación, actualización y supresión de los datos, en los términos que señale la ley.</p>' +
  '<p class="text-justify">En caso de inquietudes relacionadas con la presente autorización por favor contactarnos a través de nuestro correo electrónico info.data@tecnoquimicas.com, a la dirección Calle 23 #7-39 Cali o a la línea 018000 523339.</p>';
